import enquire from 'enquire.js';
import { md } from '../variables';
import { disableScroll, enableScroll } from '../lib/scroll';

export function catalogMenu(Alpine) {
  Alpine.data('catalogMenu', catalogMenuComponent);

  function catalogMenuComponent() {
    return {
      activeSubmenu: null,
      openedGroups: ['Популярное'],
      selectedTabs: {},
      init() {
        this.$watch('$store.header.isCatalogOpen', (isOpen) => {
          if (isOpen) {
            disableScroll();
          } else {
            enableScroll();
          }
        });

        enquire.register(`screen and (min-width: ${md}px)`, {
          match: () => {
            this.close();
          },
        });
      },
      open() {
        this.$store.header.isCatalogOpen = true;
      },
      close() {
        this.$store.header.isCatalogOpen = false;

        // wait for menu closing animation to finish
        // before resetting selected submenu
        setTimeout(() => (this.activeSubmenu = null), 200);
      },
      toggle() {
        if (!this.$store.header.isCatalogOpen) {
          this.open();
        } else {
          this.close();
        }
      },
      goto(sectionUrl: string, sectionSlug: string) {
        if (window.innerWidth < md) {
          this.activeSubmenu = sectionSlug;
        } else {
          window.location.href = sectionUrl;
        }
      },
      back() {
        this.activeSubmenu = null;
      },
      onSubsectionFocus(sectionSlug: string) {
        if (window.innerWidth >= md) {
          this.activeSubmenu = sectionSlug;

          this.$nextTick(() => this.recalculateItemsMasonry());
        }
      },
      onSubsectionBlur() {
        if (window.innerWidth >= md) {
          this.activeSubmenu = null;
        }
      },
      isGroupOpened(title: string) {
        return this.openedGroups.includes(title);
      },
      openGroup(title: string) {
        this.openedGroups = [...this.openedGroups, title];
      },
      closeGroup(title: string) {
        this.openedGroups = this.openedGroups.filter((val) => val !== title);
      },
      toggleGroup(title: string) {
        if (this.isGroupOpened(title)) this.closeGroup(title);
        else this.openGroup(title);
      },
      selectTab(section: string, index: number) {
        this.selectedTabs[section] = index;
        this.$nextTick(() => this.recalculateItemsMasonry());
      },
      isTabSelected(section: string, index: number) {
        const selectedIndex = this.selectedTabs[section] ?? 0;
        return selectedIndex === index;
      },
      recalculateItemsMasonry() {
        console.log(this.$root)
        const sublists = document.querySelectorAll<HTMLElement>(
          '.catalog-menu__submenu-container:not([hidden]) .catalog-menu__sublist'
        );
        sublists.forEach((sublist) => {
          const lastItem = sublist.querySelector('.catalog-menu__subitem:last-of-type');
          if (!lastItem) return;

          sublist.style.removeProperty('--masonry-container-width');

          const sublistRect = sublist.getBoundingClientRect();
          const itemRect = lastItem.getBoundingClientRect();
          const width = Math.ceil(itemRect.left - sublistRect.left + itemRect.width);
          sublist.style.setProperty('--masonry-container-width', `${width}px`);
        });
      },
    };
  }
}
