import enquire from 'enquire.js';
import queryString from 'query-string';
import compare from 'just-compare';
import { md } from '../variables';
import { formatPrice } from '../lib/helpers';
import { disableScroll, enableScroll } from '../lib/scroll';
import { CatalogPageRequestParams, getCatalogPage } from '../api/catalog';
import { ecommerceSendSearch } from '../lib/enhancedEcommerce';

type CategorySlug = string;
type CatalogSorting = 'popular' | 'price_desc' | 'price_asc' | 'new';
type FilterType = 'boolean' | 'numeric' | 'multiselect';

type FilterParams = {
  name: string;
  label: string;
  type: FilterType;
  filter_type: FilterType;
  postfix?: string;
  is_in_filter: boolean;
  is_in_description: boolean;
  filter_include_categories?: CategorySlug[];
  filter_exclude_categories?: CategorySlug[];
  options?: [];
};

type CatalogComponentParams = {
  page: number;
  totalPages: number;
  totalProducts: number;
  categoryId?: number;
  currentPageUrl: string;
  search: string;
  sortingDefault: CatalogSorting;
  sorting: CatalogSorting;
  minPrice: number;
  maxPrice: number;
  filterOptions: { [key: string]: FilterParams };
  filterValues: { [key: string]: any };
};

export function catalog(Alpine) {
  Alpine.data('catalog', catalogComponent);

  function catalogComponent({
    page = 1,
    totalPages = 1,
    totalProducts = 1,
    categoryId = null,
    currentPageUrl = '',
    search = '',
    filterOptions = {},
    filterValues = {},
    sortingDefault = 'popular',
    sorting = sortingDefault,
    minPrice = 0,
    maxPrice = 9999999,
  }: CatalogComponentParams) {
    return {
      page: page,
      totalPages: totalPages,
      totalProducts: totalProducts,
      currentPageUrl: currentPageUrl,
      categoryId: categoryId,
      sorting: sorting,
      minPrice: minPrice,
      maxPrice: maxPrice,
      priceIntervals: [
        { from: undefined, to: '3 000' },
        { from: '3 000', to: '5 000' },
        { from: '5 000', to: '10 000' },
        { from: '10 000', to: '20 000' },
        { from: '20 000', to: undefined },
      ],
      numericRanges: {},
      selectedPriceInterval: null,
      search: search,
      filterOptions: filterOptions,
      filterValues: { ...filterValues },
      forceApplyFilters: false,
      appliedFilters: {},
      isLoading: false,
      isFilterOpen: false,
      filterSectionOpened: null,
      isDesktopMode: false,
      collapsedFilterSections: Alpine.$persist([]).as('catalogFilterCollapsedSections'),
      shownCategoryGroups: Alpine.$persist([]).as(`catalogShownCategoryGroups_${categoryId}`),
      queryString: '',
      append: false,
      response: null,
      get normalizedFilterValues() {
        const filters = {};
        for (let name in this.filterValues) {
          if (this.isFilterSelected(name)) {
            filters[name] = this.filterValues[name];
          }
        }
        return filters;
      },
      get formattedMinPrice() {
        return formatPrice(this.minPrice);
      },
      get formattedMaxPrice() {
        return formatPrice(this.maxPrice);
      },
      init() {
        console.log('init');
        this.initAllFilters();

        console.log('page', this.page);
        console.log('sort', this.sorting);
        console.log('selected filters', { ...this.normalizedFilterValues });
        console.log('filter options', { ...this.filterOptions });
        enquire.register(`screen and (min-width: ${md}px)`, {
          match: () => {
            this.isDesktopMode = true;
            this.closeFilter();
          },
          unmatch: () => (this.isDesktopMode = false),
        });

        this.$watch('isFilterOpen', (isOpen) => {
          if (isOpen) {
            disableScroll();
          } else {
            enableScroll();
          }
        });

        this.applyFilter();
        this.$watch('normalizedFilterValues', () => {
          if (
            this.forceApplyFilters ||
            window.innerWidth >= md ||
            this.isFilterChanged('celebrities')
          ) {
            this.forceApplyFilters = false;
            this.applyFilter();
          }
          console.log('selected filters', this.normalizedFilterValues);
        });

        this.$watch('numericRanges', (ranges: { [idx: string]: string }) => {
          for (const [filter, value] of Object.entries(ranges)) {
            const vals = value.split('-');
            const from = parseInt(vals[0], 10);
            const to = parseInt(vals[1], 10);
            const fromName = `${filter}_from`;
            const toName = `${filter}_to`;

            if (from) {
              this.filterValues[fromName] = from;
            } else {
              delete this.filterValues[fromName];
            }

            if (to) {
              this.filterValues[toName] = to;
            } else {
              delete this.filterValues[toName];
            }
          }
        });
        this.updateNumericRangeSwitches();

        this.$watch('selectedPriceInterval', (selectedPriceInterval) => {
          if (selectedPriceInterval === 'not_selected') {
            this.clearFilter('from');
            this.clearFilter('to');
            return;
          }

          const interval = this.priceIntervals[selectedPriceInterval] ?? null;
          if (!interval) return;

          if (interval.from) {
            this.setFilter('from', interval.from);
          } else {
            this.clearFilter('from');
          }

          if (interval.to) {
            this.setFilter('to', interval.to);
          } else {
            this.clearFilter('to');
          }
        });
        this.updatePriceIntervals();

        this.$watch('appliedFilters, page, sorting', async (prev) => {
          this.updateTitle();
          this.updateQueryString();
          this.updatePriceIntervals();
          this.updateNumericRangeSwitches();
          this.isLoading = true;

          const params: CatalogPageRequestParams = {
            page: this.page,
            current_page_url: currentPageUrl,
            sorting: this.sorting,
            ...this.appliedFilters,
          };

          if (this.categoryId) params.categoryId = this.categoryId;
          if (this.search.length > 0) params.filter_search = this.search;

          console.log('products request', params);
          const products = await getCatalogPage(params);
          console.log('product response', products);
          console.log('pages', this.page, products.totalPages);

          if (this.append) {
            this.$refs.catalogGrid.insertAdjacentHTML('beforeend', products.html.products);
            this.append = false;
          } else {
            this.$refs.catalogGrid.innerHTML = products.html.products;
          }
          this.$refs.catalogPagination.innerHTML = products.html.pagination;
          this.$refs.catalogFilterText.innerHTML = products.html.filterText;

          this.totalProducts = products.totalProducts;
          this.totalPages = products.totalPages;
          this.minPrice = products.minPrice;
          this.maxPrice = products.maxPrice;
          this.filterOptions = products.filter;
          this.isLoading = false;
        });

        if (search.length > 0) ecommerceSendSearch(search);
      },
      async loadMore() {
        this.append = true;
        this.page++;
      },
      gotoPage(page) {
        this.page = page;
        this.scrollToTop();
      },
      gotoPrevPage() {
        if (this.page > 1) this.page--;
        this.scrollToTop();
      },
      gotoNextPage() {
        if (this.page < this.totalPages) this.page++;
        this.scrollToTop();
      },
      scrollToTop() {
        document
          .querySelector('.category__title')
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      },
      updateNumericRangeSwitches() {
        for (const [filter, options] of Object.entries(filterOptions)) {
          if (options.type === 'numeric') {
            const fromFilter = `${filter}_from`;
            const toFilter = `${filter}_to`;
            const rangeVal = `${this.appliedFilters[fromFilter] ?? ''}-${this.appliedFilters[toFilter] ?? ''}`
            if (rangeVal !== '-') {
              this.numericRanges[filter] = rangeVal;
            }
          }
        }
      },
      updatePriceIntervals() {
        if (!this.appliedFilters.from && !this.appliedFilters.to) {
          this.selectedPriceInterval = 'not_selected';
          return;
        }

        this.selectedPriceInterval = this.priceIntervals.findIndex(
          (interval) =>
            interval.from === this.appliedFilters.from && interval.to === this.appliedFilters.to
        );
      },
      updateTitle() {
        const title = document.title.replace(/\s*\| страница \d+/i, '');
        if (this.page > 1) {
          document.title = title + ` | страница ${this.page}`;
        } else {
          document.title = title;
        }
      },
      updateQueryString() {
        const qsParams = { ...this.appliedFilters };
        if (this.sorting !== sortingDefault) {
          qsParams.sorting = this.sorting;
        }

        if (this.search) {
          qsParams.filter_search = this.search;
        }

        console.log('qs', qsParams);
        let qs = queryString.stringify(qsParams, {
          arrayFormat: 'bracket',
          skipNull: true,
          skipEmptyString: true,
        });
        this.queryString = qs ? `?${qs}` : '';

        let url = this.currentPageUrl;
        if (this.page > 1) url = `${url}page/${this.page}/`;
        if (qs.length > 1) url = `${url}?${qs}`;

        window.history.pushState(null, null, url);
      },
      openFilter() {
        this.isFilterOpen = true;
      },
      closeFilter() {
        this.isFilterOpen = false;

        // wait for menu closing animation to finish
        // before resetting selected submenu
        setTimeout(() => (this.filterSectionOpened = null), 200);
      },
      filterSwitchSection(section) {
        if (this.isDesktopMode) {
          if (!this.collapsedFilterSections.includes(section)) {
            this.collapsedFilterSections.push(section);
          } else {
            this.collapsedFilterSections = this.collapsedFilterSections.filter(
              (s) => s !== section
            );
          }
        } else {
          this.filterSectionOpened = section;
        }
      },
      filterBack() {
        this.filterSectionOpened = null;
      },
      initFilterValue(name: string) {
        const params = this.filterOptions[name];
        switch (params.type) {
          case 'multiselect':
            this.filterValues[name] = [];
            break;
          case 'boolean':
            this.filterValues[name] = false;
            break;
          case 'numeric':
            this.filterValues[name] = '';
            break;
        }
      },
      initAllFilters(reset: boolean = false) {
        Object.keys(this.filterOptions).forEach((name) => {
          if (!reset && this.filterValues.hasOwnProperty(name)) return;

          this.initFilterValue(name);
        });
      },
      clearFilter(name: string, value?: string) {
        if (value) {
          // for multiselect
          this.removeFilterChoice(name, value);
        } else {
          // for single value
          this.initFilterValue(name);
          if (name in this.appliedFilters) delete this.appliedFilters[name];
        }
      },
      removeFilterChoice(name: string, value: string) {
        this.forceApplyFilters = true;
        this.filterValues[name] = this.filterValues[name].filter((v) => v !== value);
      },
      clearAllFilters() {
        this.forceApplyFilters = true;
        this.initAllFilters(true);
        console.log('clear', this.filterValues, this.normalizedFilterValues);
      },
      isFilterChanged(name: string) {
        const selectedValue = this.normalizedFilterValues?.[name];
        const appliedValue = this.appliedFilters?.[name];

        return !compare(selectedValue, appliedValue);
      },
      areFiltersChanged(names: string[] = null) {
        return names
          ? names.some((name) => this.isFilterChanged(name))
          : !compare(this.normalizedFilterValues, this.appliedFilters);
      },
      isFilterSelected(name: string) {
        if (!this.filterValues.hasOwnProperty(name)) return false;

        const value = this.filterValues[name];
        if (Array.isArray(value)) {
          if (value.length > 0) return true;
        } else {
          if (value) return true;
        }

        return false;
      },
      applyFilter() {
        if (this.areFiltersChanged()) {
          this.page = 1;
        }
        this.filterSectionOpened = null;
        this.appliedFilters = { ...this.normalizedFilterValues };
      },
      getSetFiltersCount() {
        return Object.keys(this.appliedFilters).length;
      },
      isAnyFilterSet() {
        return this.getSetFiltersCount() > 0;
      },
      isFilterSet(name) {
        return name in this.appliedFilters;
      },
      setFilter(name: string, value: string) {
        this.filterValues[name] = value;
      },
      getChoiceLabel(filter: string, value: string): string {
        const options = this.filterOptions?.[filter];
        // FIXME: the can be a race condition, when filterOptions does not contain selected choice
        //  due to user clicking filter options too fast
        const label = options?.choices?.[value]?.label;
        return label ? label : value;
      },
      getFilterChips() {
        let chips = [];

        Object.entries(filterOptions).forEach(([filter, options]) => {
          if (options.type === 'numeric') {
            const fromFilter = `${filter}_from`;
            const toFilter = `${filter}_to`;
            const label = options.label;
            const postfix = options.postfix;

            const from = this.appliedFilters[fromFilter];
            if (from) {
              chips.push({ filter: fromFilter, label, value: `от ${from} ${postfix}` });
            }

            const to = this.appliedFilters[toFilter];
            if (to) {
              chips.push({ filter: toFilter, label, value: `до ${to} ${postfix}` });
            }

            return;
          }

          if (!this.appliedFilters[filter]) return;

          const value = this.appliedFilters[filter];

          if (options.type === 'multiselect') {
            (value as string[]).forEach((variant) => {
              chips.push({
                filter,
                label: options.label,
                value: this.getChoiceLabel(filter, variant),
                variant,
              });
            });
          } else if (options.type === 'boolean') {
            chips.push({ filter, label: '', value: options.label });
          } else if (filter === 'from') {
            chips.push({ filter, label: 'Цена', value: `от ${value} ₽` });
          } else if (filter === 'to') {
            chips.push({ filter, label: 'Цена', value: `до ${value} ₽` });
          } else {
            chips.push({ filter, label: options.label, value });
          }
        });

        return chips;
      },
      formatPriceIntervalLabel(interval) {
        if (!interval.from) return `До ${interval.to} ₽`;
        else if (!interval.to) return `${interval.from} ₽ и дороже`;
        else {
          return `${interval.from}–${interval.to} ₽`;
        }
      },
      isCategoryGroupShown(name: string): boolean {
        return this.shownCategoryGroups.includes(name);
      },
      toggleCategoryGroup(name: string) {
        if (this.isCategoryGroupShown(name)) {
          this.shownCategoryGroups = this.shownCategoryGroups.filter((group) => group !== name);
        } else {
          this.shownCategoryGroups = [...this.shownCategoryGroups, name];
        }
      },
    };
  }
}
